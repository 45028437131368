
/*images*/
.image-slider {
    height:100%;
    position:relative;
    display: flex;

}

.image-slider .content{ 
    width:100%;
    padding: 0px;
    min-width:200px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.image-slider.padded .content {
    padding: 10px 60px 56px 60px;
}

.image-slider.padded {
    min-height:500px
}


.image-slider .control{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    padding:5px 15px;

}


/*Controls*/
.image-slider .control >div{
    width: 25px;
    height: 25px;
    border-left:6px solid black;
    border-top:6px solid black;
    border-radius:3px;
    cursor: pointer;
    transition: transform 0.5s ease-out;
    }
.image-slider .control >div:hover{
    transform:scale(1.3);
}
.image-slider .control .left{
    rotate: -45deg;
}
.image-slider .control .right{
    rotate: 135deg;
}
@media  (max-width:1200px) {
    .image-slider .control{
        padding:5px 15px;
    }
}



@media (max-width:820px) {
    .image-slider.padded .content {
        padding: 10px 40px 56px 40px;
    }
    .image-slider .control{
        padding:5px 5px;
    }
}

@media (max-width:450px) {
    .image-slider.padded .content {
        padding: 10px 0px 56px;
    }
    .image-slider.padded {
        min-height: 300px;
    }
}



/**/
.image-slider .images {
    width: 100%;
    position: relative;
}
.image-slider .image {
    height: 100%;
    width: 100%;
    position: absolute;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: 100% fit-content(100%);
}

.image-slider .image-case {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-slider img.landscape {
    width: 100%;
    height: auto;
}

.image-slider img.portrait {
    width: auto;
    height: 100%;
}

.image-slider img{
    max-width: 100%;
    max-height: 100%;
}
/*contain if high is bigger*/
.image-slider .image-case.contain{
    height: 100%;
}
/*hide before loaded*/




/*Fade the image*/
.image-slider.fade .image{
    opacity: 1;
    transition: none;
}

.image-slider.fade .images:not(:has(.image.hidden)) .image:nth-of-type(2) .image-case {
    transition: opacity 2.5s ease-in-out;
    opacity: 0;
}




/*Slide the image*/

.image-slider.slide .images .image{
    transition: transform 0.8s ease-in-out;
    transform:translateX(0%);
}

.image-slider.slide .images.slide .image:nth-child(1) {
    transform:translateX(0%);
}


.image-slider.slide .images.slide.left .image.hidden{
    transform:translateX(200%);
}
.image-slider.slide .images.slide.right .image.hidden {
    transform:translateX(-200%);
}


.image-slider.slide .images.slide.left .image:nth-child(2) {
    transform:translateX(-200%);
}
.image-slider.slide .images.slide.right .image:nth-child(2) {
    transform:translateX(200%);
}




/*standarrd*/



.image-slider .caption {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    width: 100%;
    padding: 8px 16px;
    font-size: 14px;
    justify-content: center;
    transition: opacity 0.4s ease-in-out,transform 0.8s ease-in-out;
    transform:translateX(0%);
    
    position: absolute;
    bottom: -38px;

}

/*Slide the image*/

.image-slider.slide .images .image .caption{
    transform:translateX(0%);
    opacity:1;
}

.image-slider.slide .images.slide .image:nth-child(1) .caption{
    transform:translateX(0%);
    opacity:1;
}


.image-slider.slide .images.slide.left .image.hidden .caption{
    transform:translateX(-725px);
    opacity:0;
}
.image-slider.slide .images.slide.right .image.hidden .caption {
    transform:translateX(725px);
    opacity:0;
}


.image-slider.slide .images.slide.left .image:nth-child(2) .caption{
    transform:translateX(725px);
    opacity:0;
}
.image-slider.slide .images.slide.right .image:nth-child(2) .caption{
    transform:translateX(-725px);
       opacity:0;
}



.image-dot > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height:20px;

    cursor: pointer;
}




.image-dot > div > div{

    width: 8px;
    height:8px;
    border-radius: 50px;
    background-color: rgb(197, 197, 197);
    box-shadow: 0px 0px 1px 0px #030303;
    transition:background-color 0.7s ease-in,width 0.7s ease-in,height 0.7s ease-in,transform 0.2s ease-in;
    transform:scale(1)

}
.image-dot > div.active > div{
    width: 12px;
    height:12px;
    background-color: rgb(84, 84, 84);

}
.image-dot > div:not(.active):hover >div{

    transform:scale(1.3)

  
}
.image-dot{
    height:15px;
    padding:10px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0px;
    grid-gap: 5px;
    flex-direction: row-reverse;
}