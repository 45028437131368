
.aboutpage{
    display:grid;
    padding: 0px 35px;
    grid-template-columns:2fr 3fr;
}
.contact-image{
    padding:35px
}
@media screen and (max-width:1350px){
    .aboutpage{
        padding: 0px 25px;
        grid-template-columns:2fr 4fr;
    }
}

@media screen and (max-width:860px){
    .aboutpage{
        padding: 0px 15px;
        grid-template-columns: 1fr;
    }
    .contact-image{
        padding:15px
    }
}

@media screen and (max-width:695px){
    .aboutpage{
        display:block;
        grid-template-columns: 1fr;
        padding: 0px 5px;
    }
    .contact-image{
        padding:0px;
    }
}
