.popup-container{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    transition: background-color 0.4s;
   background-color: none;
   z-index:100;
   
}
.popup-bar{
    min-height: 36px;
    width: 100%;
    padding: 2px;
    display: grid;
    grid-template-columns: auto 34px;


}

.popup-bar::after{
    width: 80%;
    height: 1px;;
    border-bottom:1px solid black;
}

.popup-container.open{
    background-color: rgba(0, 0, 0, 0.511);
}
.popup {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    position: absolute;
    transform: scale(0);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: transform 0.4s ease-in;
    transition: opacity 0.4s ease-in;
  }
  .popup-container.open .popup {
    transform: scale(1);
  }
.popup.large{
width:70%;
height:90%;
top:12%;
}
.popup.medium{
    width:60%;

top:15%;
height: 70%;
}
.popup.small{
    width:32.4%;
height:50%;
top:20%;
}
.popup.flex{
    top:5%;
    width: 80%;
    max-width:1300px;
}
@media screen and (max-width: 900px) {
    .popup.flex{

        width:90%;
    }
}
@media screen and (max-width: 600px) {
    .popup.flex{

        width: 100%;
    }
}

.popup-title{
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    padding-top: 16px;
}

  
  .popup-content {

  max-height:82vh;
    text-align: center;
    height: 100%;
    overflow-y:auto;
  }
  
  .popup-content button {
    background-color: #00bcd4;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 0.9em;
    outline: none;
    padding: 10px 20px;
  }
