@import url('https://fonts.googleapis.com/css2?family=Krub:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

:root {
    --base: rgb(255 254 253);
    --brown: rgb(70, 44, 21);
    --black:rgb(90, 90, 90);
    --grey:rgb(140, 140, 140);
    --monserrat: 'Montserrat', sans-serif;
    --titillium:"Titillium Web", serif;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


*{
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -ms-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-font-smoothing: antialiased;
   
}
html,
body {
    width: 100%;
    height: 100%;
}

.background {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -100;
    background: var(--base);
}

.App {
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
     font-family:var(--titillium)

}
*::after {
    content: none;
}
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; /* For some browsers */
}
a:-webkit-any-link {
    color: black;
    cursor: pointer;
    text-decoration: none;
}
.container {
    padding: 0px 10px 20px 10px;
}

.sect-mid {

    display: grid;
    grid-template-rows: 1fr fit-content(100%);


    max-width: 1470px;
    width: 100%;
    /* height: 100%; */
    margin: 0px auto;
    overflow-y: auto;
}




/*////////////////////////////////////*/

.head-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 254 253);
    z-index: -25;

}



.scroller {
    width: 300px;
    height: 100px;
    overflow-y: auto;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
}

.text-overlay {
    width: 100%;
    color: rgb(70, 44, 21);
    text-align: center;
    font-weight: 400;

}

.text-overlay .title {
    font-size: calc(min(79px, 5vw) + 30px);

}

.text-overlay .subtitle {
    font-size: calc(min(28px, 1.5vw) + 15px);
    font-style: italic;
}


/*Profile image*/
.img-profile {
    padding: 20px;
    margin: 20px;
    margin: 2vh 16vw 2vw 5vw;
    opacity: 1.;
}

.case-profile-border {
    position: relative;

}

.img-profile img {
    border: 2px solid black;
    height: 30vh;
}

.img-profile-border {
    border: 2px solid black;
    padding: 20px;
}







/*React CssTransition*/
.alert-enter {
    opacity: 0;
    /*  transform: scale(0.9);*/
}

.alert-enter-active {
    opacity: 1;
    /* transform: translateX(0);*/
    transition: opacity 2000ms, transform 2000ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    /*  transform: scale(0.9);*/
    transition: opacity 2000ms, transform 2000ms;
}



/* transition.css */
.route-fade-enter {
    opacity: 0;
  }
  
  .route-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .route-fade-exit {
    opacity: 1;
  }
  
  .route-fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }







.app-content{
    display: grid;
    grid-template-rows: fit-content(100%) auto;
    height: 100vh;
    width:100%;
}

.title{
    font-family: var(--monserrat);
    text-align: center;
    margin:0px;
    font-size: min(40px, calc(23px + 1vw));
}



/*scrollbar*/
::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-track{
	border-radius: 10px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #898989;
}/*
::-webkit-scrollbar-button {
    height: 12px;
  }
*/



/*pages*/

.page {
 


    min-width: 100%;
    max-width: 1580px;

}