.content-box{

    padding: 5px 20px;
    margin: 0px auto;
    /* box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.185); */
    max-width: 906px;
    text-align: center;
}
@media screen and (max-width: 800px) {
    .content-box{
        margin:10px;
        padding:15px;

    }
}