/*PROJECYT*/
.overlay-image {
    position: absolute;

}

.project-outer .project {
    display: block;
    position: relative;
    width: 420px;
    height: 300px;
   /* box-shadow: rgb(0 0 0 / 48%) 0px 2px 4px 0px;*/
    box-shadow: rgb(0 0 0 / 16%) 0px 2px 6px 0px;
  transition: transform 0.1s ease-out;
  transform: scale(1);

}

.project-outer .project:hover,
.project-outer .project:focus-within      {
    transition: transform 0.1s ease-in;
    transform: scale(1.01);
}

.project-container {
    display: flex;
    flex-wrap: wrap;

    flex-wrap: start;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;
}

.project .header {
    font-weight: 600;

}

.project .content {
    padding: 9px;
    height: 100%;
    display: grid;
    grid-template-rows: auto 48px;
    grid-gap: 4px;

}

.project .card-title {
    display: flex;

    justify-content: space-between;
}

.project .card-mention {
    font-style: italic;
    font-size: 12px;
}

.project .card-text {

    justify-content: space-between;
    margin: 0px 5px 10px 5px;
    align-items: center;
}

.project .info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.project .info-style {
    padding: 10px;
    background: #ffffff;

    margin: 10px 0px;
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 0px;

}

.project .front {

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.project .c-title {
margin:0px;
    font-size: 15px;
    font-weight: 600;
    font-family: var(--monserrat);

}




.project-outer {
    margin: 24px;
    cursor: pointer;
}

@media screen and (max-width: 1002px) {
   .project-outer .project {
        width: 315px;
        height: 255px;
    }

    .project-outer {
        margin: 16px
    }
}

@media screen and (max-width: 760px) {
    .project-outer .project {
        width: 420px;
        height: 300px;
    }

    .project-outer {
        margin: 10px
    }

    .project-container {
        padding: 10px;
    }

}

@media screen and (max-width: 490px) {

    .project-outer .project {
        width: 85vw;
        height: calc(60vw + 40px);

    }

    .project-outer {
        margin: 10px 5px
    }

    .project-container {
        padding: 5px;
    }

}

/*
@media screen and (max-width: 400px) {
    .project{
        width: 252px;
    height: 210px;
    }
}


*/

/*///////////////////////////////////*/


.project-expand .grid {
    display: grid;
    justify-content: center;
    width: 100%;
    grid-template-columns: 4fr 2fr;
    padding: 5px 20px;
}

.project-expand .main {
margin-top:10px;
    display: grid;
    grid-template-columns: 1fr 60px;
}

.project-expand .year {
    font-size: 20px;
    font-weight: bold;

}

.project-expand .producent {
    border-right: 2px solid black;
    font-size: 16px;
    font-style: italic;
    text-align: end;
    padding: 4px 12px;

}

.project-expand .details {
    padding-right: 50px ;
margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0px auto;
}


.project-expand .meta {
    margin-bottom: 20px;
    text-align: start;
    padding: 20px 5px
}

.project-expand .meta p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    font-style: italic;
    padding: 1px 0px 0px 7px;

}

.project-expand .description {

    max-width: 800px;
    margin: auto;
    font-style: italic;
    text-overflow: ellipsis;
    overflow-y: auto;



}
.project-dots {

}
.project-dots div{
    margin:auto;
    width: 68px;
    height: 36px;
        border-bottom: 8px dotted var(--black);
}
.project-expand .description,.project-expand .details 
{
    width: 100%;
    padding: 20px 20px 50px 20px;
    /* padding: 10px 20px 50px 20px;*/
    max-width: 800px;
 
}
.project-expand .details {

    padding: 0px 60px 50px 20px;

 
}
.project-expand .credits {
    font-size: 13px;
    line-height: 1.5;
    text-align: justify;
}

@media screen and (max-width: 1280px) {
    .project-expand .grid {
       grid-template-columns: 1fr;
    
    }

    .project-expand .description,
    .project-expand .details {
        padding: 10px 50px 50px 50px;
    }
}


@media screen and (max-width: 820px) {
    .project-expand .description,
    .project-expand .details {
        padding: 10px 25px 50px 25px;
    }

    .project-expand .grid {

        padding: 5px 10px;
    }
}
@media screen and (max-width: 450px) {
   /*
    .project-expand .description,
    .project-expand .details {
     
    }*/

    .project-expand .grid {
        padding: 2px 0px;
    }
}

.grid2 {
    display: grid;
    grid-template-columns: fit-content(100%) 5fr;
}
.project-outer .project {
    opacity:0;
    transition:opacity 0.4s ease-in-out;
}
.project-outer.loaded  .project{
    opacity:1;
}
.project-outer.hidden ~ .project-outer.loaded .project{
    opacity:0;
    }

