
.nav-section{
    position:relative;
        line-height: 90%;
  
    font-size:38px;
    white-space: nowrap;
}

.nav-section nav {
    padding-top: 15px;
    font-size: 20px;
    font-family: var(--monserrat);
    display: grid;
    grid-template-columns: fit-content(100%) auto;
}


.nav-head{
    height: 64px;
    justify-content: start;
    display:flex;
    align-items: center;
   padding:10px
    
}
.nav-head .nav-title{
    color: var(--grey);
    font-weight: 400;
    white-space: nowrap;
    font-size: min(40px,calc(23px + 1vw));
    font-family: var(--monserrat);
}

.nav-container{
    margin:0px;
    padding:0px;
    display:flex;
    justify-content: space-around;
   text-align: center;
   align-items: end;
}





.nav-container > li {
    display: flex;
    cursor: pointer;
    justify-content: end;
    padding-bottom:12px;
 /*   align-items: center;*/
    height: 58px;
    width: 0px;
    flex-direction: column;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in;
    opacity:1;
    font-weight: 600;
    color:var(--black);
    align-items: center;


}


.nav-expanded.closed .nav-container > li{
    height:0px;
    opacity: 0;
}


.nav-container > li > a {
    transition: inherit;
}

.nav-container > li:hover > a {
    font-size: 24px
}

/*Nav line and point*/

.nav-line {
    background-color: var(--black);
    height: 1px;
    margin-bottom: 10px;
    width: 100%;

    transition: top .2s ease-in-out;
}
.nav-line > div, .nav-section > nav{
    max-width: 1470px; 
    margin: 0px auto; 
}

.nav-point-container {
    display: flex;
    transition: all 300ms ease-in-out;
    position: relative;
    width: fit-content;

}

.nav-point {
    display: grid;
    grid-template-columns: 8px 8px;
    grid-template-rows: 8px 8px;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -12px;

}

.spacer {
    height: 2vh
}

.nav-point div {
    margin: 0px;
    padding: 0px;
    border-radius: 40%;
    width: 16px;
    height: 16px;
    float: left;
    border: 1.5px solid var(--black);
    rotate: 45deg;

}



.page-line {
    background-color: var(--black);
    height: 1px;
    width: 80%;
    margin: 2px auto;
}



/* responsive*/





@media (max-width: 1100px) {
    .nav-section{
        font-size:32px;
     }
     .nav-head{
        justify-content: center;
    }
}
@media (max-width: 860px) {
    .nav-container {
        align-items: start;
        flex-direction: column;
    }
    .nav-container > li{
        align-items: start;
        padding-bottom:0px;
        justify-content: center;
    }
    .nav-section{
        font-size:22px;
        margin-bottom: 0px;
     }
     .nav-section nav {
        flex-direction: column;
        width: 70%;
        padding:0px;
        grid-template-columns: none;
    }
    .nav-line {
        position:absolute;
    }
}


@media (max-width: 620px) {


    .nav-section{
        font-size:30px;
     }
}
@media  (max-width: 400px) {
    .nav-head{
        font-size:24px;
     }
}


/*Arrow Icons*/

.arrow-icon{
    margin-left: -15px;
    margin-top: 28px;
    display: flex;
    transition:margin 0.3s ease-in-out;
}
.arrow-icon.open{
    margin-top: 18px;
}
.arrow-icon div{
    border-radius: 5px;
    width: 2px;
    height: 15px;
    background: #000000;
    transform-origin: top;
    transition: rotate 0.3s ease-in-out;
}

.arrow-icon.open div:nth-child(1){
    rotate: 315deg;
}
.arrow-icon.open div:nth-child(2){
    rotate: 45deg;

}
.arrow-icon.close div:nth-child(1){
    rotate: 225deg;
}
.arrow-icon.close div:nth-child(2){
   
    rotate: 135deg;
}