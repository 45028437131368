.btn-container{
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
}

.button.standard {
    display:flex;
    justify-content: center;
    padding: 10px 5px;
  }
 
.button.standard button,.button.standard label{
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #a1adb5;
    border: none;
    border-radius: 4px;

  }
.button.standard.active button,.button.standard.active label{
    background-color: #3498db;
    cursor: pointer;
}


  .actions {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.close-icon{
    opacity:0.5;
    cursor:pointer
}
.close-icon:hover,.close-icon:active{
    opacity:1;
}