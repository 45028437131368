/*Painting image*/
.img-painting {
    transform-origin: top center;
    transition: transform 2s ease-in;
    margin: 25px;
}

.img-painting > div{

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-painting .shadow {
    height: 3px;
    width: 102%;
    margin-left: -1%;
    background: linear-gradient(180deg, #686565 0%, transparent 100%);
}
.img-painting .case-border {
    padding: 6px;
}

.img-painting .case-inner {
    border: 2px rgb(224, 224, 226) solid;
    overflow: hidden;

    display: flex;
}
.img-painting .img-case {
    width: 100%;
    width: 250px;
    height: 375px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    scale: 1.0;
    transition: scale ease-out 0.7s, background-position ease-in-out 24s;

}

/*zoom*/
.img-painting .img-case:hover {
    scale: 1.03;
}
.img-painting .img-case.slide-left {
    background-position-x: 76%;
}



/*Gallery display*/
.img-gallery {
    overflow: hidden;
    display: grid;
    padding: 30px;
    margin-left: 0px;
    /* width: 100%; */
    grid-auto-flow: column;
    justify-content: center;
}
.img-gallery{
    
    opacity:1;
    transition:opacity 0.4s ease-in-out;
}
.img-gallery:has(.img-painting.hidden){
    opacity:0%
}
.img-gallery .hidden img{
    max-width:100px; 
    max-height:100px;
}

@media (max-width: 1300px) {
    .img-gallery {
        padding: 20px;
    }

    .img-painting {
        margin: 20px;
    }
    
}

@media (max-width: 1270px) {
    .img-gallery {
        padding: 10px;
       
    }
    .img-painting .img-case {
        width: 200px;
        height: 300px;
    }

}
@media (max-width: 1075px) {
    .img-gallery {
        grid-template-rows: 1fr 1fr;
    }
    .img-painting .img-case {
        width: 250px;
        height: 375px;
    }

    .img-painting {
        margin: 20px 30px;
    }
}
@media (max-width: 700px) {


    .img-painting {
        margin: 15px 15px;
    }
}
@media (max-width: 620px) {
    .img-gallery {
        grid-template-rows: none;
        grid-auto-flow: row;
        padding: 5px;
    }

    .img-painting {
        margin: 15px;
    }
}
@media (max-width: 340px) {
    .img-painting .img-case {
        width: 200px;
        height: 300px;
    }
}
@media (max-width: 250px) {
    .img-painting .img-case {
        width: 150px;
        height: 225px;
    }
}