button.icon {
    border: unset;
    background: unset;
    cursor: pointer;
}


.icon {
    padding: 10px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--brown);
    background: var(--brown);
}


.icon .icon-image {
    color: var(--base)
}

/*alternate*/
.icon.alternate {
    background: #462c151c;

}

.icon.alternate .icon-image {
    color: var(--brown);
}




.icon:hover,
.icon:active {
    background: var(--base);
}

.icon:hover .icon-image,
.icon:active .icon-image {
    color: var(--brown);
}


/*alternate*/
.icon.alternate:hover,
.icon.alternate:active {
    background: var(--grey);

}

.icon.alternate:hover .icon-image,
.icon.alternate:active .icon-image {
    color: var(--base);
}


/*///////////////////*/

.social-container {
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: end;
    flex-wrap: wrap;
    margin-top: 15px;
    grid-gap: 10px;
}


/*
 .social-icons{
    background: var(--brown);
    padding:12px;
    border-radius: 50%;
    cursor:pointer;
    margin: 5px

}
.copy .social-icons{
    background:none;
    color: var(--brown)!important;
    padding:12px;
    border-radius: 50px;
}
*/

@media screen and (max-width: 600px) {
    .social-container {
        justify-content: center;
        margin-top: 20px;
    }
}





.contact-container {
    display: flex;
    /* justify-content: center; */
    align-items: end;
    flex-direction: column;
    /* padding: 89px 0px 8px 60px; */
    margin-top: 108px;
    margin-right: -200px;
    z-index: 1;
}

.contact-container>ul {
    box-shadow: 0px 1px 1px 1px rgb(90 90 90 / 19%);
    background: rgb(255 255 255);
    list-style-type: none;
    padding: 0px 10px;
}
.contact-container div{
    display: flex;
    align-items: center;
grid-gap:5px;
}
.contact-container li>div {
    display: flex;
    align-items: center;
    /* grid-template-columns: max-content 300px repeat(5,max-content);*/
    grid-gap: 20px;
    justify-content: space-between;
    /* border: 1px solid var(--grey); */
    padding: 20px 15px;
    margin: 5px;
    max-width: 600px;
    min-width: 490px;


}


.contact-container .contact-name {
    font-size: 20px;
    letter-spacing: 1.5px;
}

@media screen and (max-width:1200px) {
    .contact-container {
        margin-top: 70px;
        margin-right: -160px;
        transform: scale(0.9);
    }
}

@media screen and (max-width:1050px) {
    .contact-container {
        margin-top: 40px;
        margin-right: -140px;
        transform: scale(0.8);
    }

}

@media screen and (max-width:860px) {
    .contact-container {
        margin-top: 0px;
        margin-right: -62px;
        transform: scale(0.8);
        margin-bottom: -54px;
    }



}

@media screen and (max-width:695px) {
    .contact-container {

        margin: 0px;
        align-items: center;
    }
}



@media screen and (max-width: 500px) {
    .contact-container {

        transform: scale(0.7);
    }
}

@media screen and (max-width: 380px) {
    .contact-container {

        transform: scale(0.6);
    }
}

@media screen and (max-width: 300px) {
    .contact-container {

        transform: scale(0.5);
    }
}







.clipboard div {
    opacity: 0%;
    padding: 5px 10px;
    border-radius: 50px;
    box-shadow: #000000bf 0px 0px 3px 0px;
    transition: opacity 0.4s ease-out;

    white-space: nowrap;
    background-color: white;

}

.clipboard {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    left: 50%;
    width: 0px;
    z-index: 100;

}

.copy-text {
    border: unset;
    background: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border-radius: 50px;
    
}

.copy-icon {
    padding: 4px;
    border-radius: 50px;
    display: flex
}



.copy-icon .icon-image {
    padding: 4px;
}


.copy-text .icon-image {
 color:var(--brown);
 opacity:0.4;
 transform: scale(1);
 transition:all 0.05s ease-out;
}

.copy-text:hover {
    
    background: #f6f6f6
}

.copy-text:active {
    
    background: #ebebeb
}


.copy-text:hover .icon-image,
.copy-text.copied .icon-image
  {
    transform: scale(1.1);
    opacity:1;

}